import React from 'react';
import { breakpoints } from '@naf/theme';
import styled, { css } from 'styled-components';
import { Tooltip } from '@naf/parts';

type FieldVariant = 'featuredModel' | 'resultCard';

interface FieldProps {
  label: string;
  value?: number | string;
  unit: 'km' | 'kWh' | 'min' | 'kW' | 'kr';
  difference?: { diff: number; color: string; unit: string } | null;
  explicitlyMissing?: boolean;
  explicitlyMissingLabel?: string;
  tooltipText?: string;
  variant?: FieldVariant;
}

export const Field: React.FC<FieldProps> = ({
  label,
  value,
  unit,
  difference,
  explicitlyMissing,
  tooltipText,
  explicitlyMissingLabel,
  variant = 'resultCard',
}) => (
  <StyledField $variant={variant}>
    <Label $variant={variant}>
      {label}{' '}
      {tooltipText && (
        <TooltipWrapper
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Tooltip text={tooltipText} />
        </TooltipWrapper>
      )}
    </Label>
    <Value $variant={variant}>
      {value && value !== -Infinity ? (
        <>
          {value.toLocaleString('nb-NO')} {unit}{' '}
          {difference && (
            <Difference $color={difference.color}>
              ({difference.diff > 0 ? '+' : ''}
              {difference.diff}
              {difference.unit})
            </Difference>
          )}
        </>
      ) : (
        missingLabel(explicitlyMissing, explicitlyMissingLabel)
      )}
    </Value>
  </StyledField>
);

const missingLabel = (explicitlyMissing?: boolean, explicitlyMissingLabel?: string) =>
  explicitlyMissing ? explicitlyMissingLabel || 'Ikke oppgitt' : 'Kommer senere';

const StyledField = styled.div<{ $variant: FieldVariant }>`
  display: flex;

  font-size: 1rem;
  align-items: end;

  @media (max-width: ${breakpoints.s}) {
    font-size: 0.875rem;
  }
`;

const Label = styled.div<{ $variant: FieldVariant }>`
  flex-grow: 1;
  display: flex;
  align-items: end;

  ${({ $variant }) =>
    $variant === 'featuredModel'
      ? css`
          width: 50%;
          text-align: left;
        `
      : css`
          flex-grow: 1;
          text-align: right;
        `}
`;

const Value = styled.div<{ $variant: FieldVariant }>`
  ${({ $variant }) =>
    $variant === 'featuredModel'
      ? css`
          width: 50%;
          text-align: left;
        `
      : css`
          font-weight: bold;
          flex-grow: 1;
          text-align: right;
        `}
`;

const Difference = styled.span<{ $color: string }>`
  font-weight: normal;
  color: ${({ color }) => color};
`;

const TooltipWrapper = styled.div`
  height: 26px;
  margin-left: 4px;
`;
